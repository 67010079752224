import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';
import { Table, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { Operator } from '../../../modules/cs/common/components/operator';

const CSGuildCoopPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Guild Coop</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/icon_guild_raid.png"
            alt="Guild raid"
          />
        </div>
        <div className="page-details">
          <h1>Guild Coop</h1>
          <h2>This guide contains information about the Guild Coop mode.</h2>
          <p>
            Last updated: <strong>03/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          Consortium Co-op Battle (commonly known as Guild Coop) is a
          semi-competitive cooperative PVE game mode exclusive to Consortiums,
          CounterSide’s equivalent to player guilds. Guild Coop sees consortiums
          challenge seasonal bosses for rewards, as well as compete against
          other consortiums for leaderboard ranking.
        </p>
        <SectionHeader title="Guild Coop Overview" />
        <p>
          For every week of the season, consortium members will each get 5 arena
          attempts and 5 boss attempts that can be used to enter their
          respective stages.
        </p>
        <SectionHeader title="Arenas" />
        <p>
          Each week, four arenas will be made available. Arenas can be broken
          down into three components:
        </p>
        <ul>
          <li>
            The arena stage: Arenas are a single stage that can be cleared
            repeatedly. The stages are fairly trivial and all can be cleared
            with a decently geared team.
          </li>
          <li>
            The special rules: Arenas come with arena-specific debuffs that
            affect how you approach the stage. Arena 12, the hardest arena, will
            always have the same DP Gain -30% debuff.
          </li>
          <li>
            The artifact track: Every arena comes with an artifact track, which
            rewards the consortium with artifacts for repeatedly clearing the
            arena.{' '}
          </li>
          <ul>
            <li>
              Artifacts are buffs applied to all consortium members when they
              attack the boss.{' '}
            </li>
            <li>
              The artifact track is advanced depending on the medals received
              for clearing the respective arena. There are three medal types
              Gold, Silver, and Bronze which grant 30%, 20% and 10% progress
              towards an artifact respectively.
            </li>
            <li>Each arena comes with 12 artifacts.</li>
          </ul>
        </ul>
        <p>
          Players are encouraged to coordinate with their guilds to determine
          which arenas to hit to obtain the best combination of buffs from the
          given week’s artifacts. A summary sheet has been provided below to
          more easily visualize the above:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/worm_1.webp"
          alt="Guide"
        />
        <SectionHeader title="Guild boss" />
        <p>
          There are a couple items to keep in mind regarding the guild boss:
        </p>
        <ul>
          <li>
            <strong>Availability</strong>: The guild boss can be attacked from
            the moment the week resets. However, as mentioned above, players are
            encouraged to coordinate and collect arena artifacts with their
            consortiums to maximize the buffs they have available when they
            challenge the guild boss.
          </li>
          <li>
            <strong>Boss level</strong>: Defeating the boss will reset the
            health bar and increase the boss stage.{' '}
          </li>
          <ul>
            <li>
              Progressing the boss stage increases the boss’ base stats and
              introduces new mechanics.{' '}
            </li>
            <li>
              There are a total of 15 boss stages but all rewards can be
              obtained by defeating stage 6 weekly.
            </li>
            <li>
              Damage dealt to bosses will not carry forward into the next stage;
              killing the boss will end the attempt, regardless of the time
              remaining.
            </li>
          </ul>
          <li>
            <strong>Positioning:</strong> Guild boss fights take place on a
            significantly larger map with looser deployment restrictions,
            allowing you to place characters in front and behind the boss.{' '}
          </li>
          <li>
            <strong>Boss specific mechanics</strong>: Each guild boss comes with
            its boss specific mechanics. These will be discussed in greater
            detail below.
          </li>
        </ul>
        <SectionHeader title="Rewards" />
        <p>
          Players are incentivized to participate in Guild Coop for the numerous
          opportunities to earn seasonal rewards. These include:
        </p>
        <ul>
          <li>
            <strong>Total Participation track</strong>: Using your entry
            attempts will progress the Total Participation track, which will
            issue rewards at regular intervals.
          </li>
          <li>
            <strong>Kill Points track</strong>: Consortium damage on the boss
            will progress the Kill Points track, which will issue rewards at
            regular intervals.
          </li>
          <li>
            <strong>Boss kill rewards</strong>: Consortium members receive
            consortium tokens depending on the number of boss stages they clear,
            and info depending on the number of artifacts they collect.
          </li>
        </ul>
        <SectionHeader title="Guild Boss Strategy" />
        <Tabs
          defaultActiveKey="Boss1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab eventKey="Boss1" title="Demolition Worm" className="with-bg">
            <h5>Demolition Worm</h5>
            <p>
              Worm introduces new mechanics at stages 3, 5 , 7, and 8. However,
              the general strategy for the boss fight does not change until
              stage 8.
            </p>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Stages</th>
                  <th>Enhanced Attack</th>
                  <th>Bladesaw Skill</th>
                  <th>Laser Skill</th>
                  <th>Other</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">1-2</td>
                  <td>Not used</td>
                  <td>1 Roar</td>
                  <td>Ult used after 90s. Can't be canceled.</td>
                  <td>Boss is immune to ASPD debuffs</td>
                </tr>
                <tr>
                  <td className="left">3-4</td>
                  <td>Used</td>
                  <td>2 Roars</td>
                  <td>
                    Ult used after 2 Bladesaw skills. Can be canceled by dealing
                    0.01% of total HP. Boss gains 40% Res during the check.
                  </td>
                  <td>Boss is immune to ASPD debuffs</td>
                </tr>
                <tr>
                  <td className="left">5-6</td>
                  <td>Used</td>
                  <td>2 Roars</td>
                  <td>
                    Ult used after 1 Bladesaw skill. Can be canceled by dealing
                    0.01% of total HP. Boss gains 60% Res during the check.
                  </td>
                  <td>Boss is immune to ASPD debuffs</td>
                </tr>
                <tr>
                  <td className="left">7</td>
                  <td>Used</td>
                  <td>1 Roar</td>
                  <td>Ult used after 2 Bladesaw skills. Can't be canceled.</td>
                  <td>Boss is immune to ASPD debuffs</td>
                </tr>
                <tr>
                  <td className="left">8-15</td>
                  <td>Used</td>
                  <td>1 Roar</td>
                  <td>Ult used after 2 Bladesaw skills. Can't be canceled.</td>
                  <td>
                    Boss is immune to ASPD debuffs. It also gains: 20% Res, 100%
                    CC Res, Immunity to Silence and Skill Haste debuffs.
                  </td>
                </tr>
              </tbody>
            </Table>
            <h5>Stage 1-6 Basic Strategy</h5>
            <p>
              Worm is vulnerable to skill cooldown reduction, skill seal, and
              silence for the first 7 stages. These debuffs can be used to
              prevent Worm from ever using its ultimate, trivializing the boss
              fight. The units used for this strategy are:
            </p>
            <div className="employee-container">
              <Employee slug="jin-bora" mode="icon" enablePopover />
              <Employee slug="mone" mode="icon" enablePopover />
              <Employee slug="john-mason" mode="icon" enablePopover />
            </div>
            <ul>
              <li>
                Jin Bora: Jin Bora applies +1s skill cooldown for targets hit by
                her basic attack. Jin Bora prevents the boss from casting any
                special or ultimate skills.
              </li>
              <li>
                Mone: In the absence of Jin Bora, Mone can be used instead to
                achieve a similar effect. Mone is slightly less consistent than
                Jin Bora but she was historically the go-to character for the
                content.
              </li>
              <li>
                Mone + Mason: Mone can be supplemented with John Mason if Mone
                alone is too unreliable.
              </li>
            </ul>
            <StaticImage
              src="../../../images/counterside/generic/worm_2.webp"
              alt="Guide"
            />
            <h5>Stage 7+ Basic Strategy</h5>
            <p>The new mechanics introduced at stage 8 are:</p>
            <ul>
              <li>
                Immunity to skill cooldown reduction and skill seal: Instead of
                ignoring the raid mechanics by preventing the boss from casting,
                strategies will now need to account for the impact of the boss’
                various skills.
              </li>
              <li>
                50% debuff resistance: debuff resistance reduces the
                effectiveness of offensive debuffs (DEF shred) by halving the
                effect and duration of debuffs applied.
              </li>
            </ul>
            <h5>Team Building Considerations</h5>
            <p>
              These new mechanics are covered by characters that fulfill
              specific roles. These roles are:
            </p>
            <ul>
              <li>Ship defender</li>
              <li>Battery</li>
              <li>Healer</li>
              <li>Shielder</li>
            </ul>
            <p>
              <strong>Ship defender</strong>
            </p>
            <p>
              Ship defender units are used to kill the waves of mobs the boss
              summons thus protecting your ship. Units used for this role must
              have the ability to live through the bosses two map wide attacks
              that can hit them. Typical units for this role are: Awakened Lee
              Sooyeon, Awakened Rosaria, Awakened Horizon*, and Awakened Chris*.{' '}
            </p>
            <div className="employee-container">
              <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
              <Employee slug="awakened-rosaria" mode="icon" enablePopover />
              <Employee slug="awakened-horizon" mode="icon" enablePopover />
              <Employee slug="griffin-rider-chris" mode="icon" enablePopover />
            </div>
            <p>
              *Awakened Horizon and Awakened Chris survive the boss’ mapwide
              attacks by i-framing the damage using the manual ultimate
              function.
            </p>
            <p>
              <strong>Battery</strong>
            </p>
            <p>
              Battery units are used to accelerate DP generation, allowing you
              to get the full team of 16 out in a reasonable amount of time.
              This role is fulfilled by Lin Xien.
            </p>
            <div className="employee-container">
              <Employee slug="lin-xien" mode="icon" enablePopover />
            </div>
            <p>
              <strong>Healer</strong>
            </p>
            <p>
              Healers are needed to sustain your team through damage. The better
              healers will also provide some form of buff/ debuff utility to
              provide more than just healing. Notable healers are: Yang Harim,
              Rearmed Chinatsu, and Evelyn Keller.
            </p>
            <div className="employee-container">
              <Employee slug="yang-harim" mode="icon" enablePopover />
              <Employee slug="rearmed-chinatsu" mode="icon" enablePopover />
              <Employee slug="evelyn-keller" mode="icon" enablePopover />
            </div>
            <p>
              <strong>Shielder</strong>
            </p>
            <p>
              Shielders are used to clear Demolition Worm’s boss mechanic. The
              second variation of the boss’ ultimate skill, the “burrow”, flings
              all characters to your ship and rams them for a lethal amount of
              damage. It is impossible to eat the damage head-on. To tank this
              attack, players will need to protect their units with barriers,
              which each absorb an infinite amount of damage. The unit that
              covers this role best is Awakened Hilde with her Alternium
              Reactor.
            </p>
            <div className="employee-container">
              <Employee slug="awakened-hilde" mode="icon" enablePopover />
            </div>
            <p>
              If a player does not have her reactor, you may use Awakened Hilde
              in combination with the base form of Kaci Bins to achieve the same
              effect.
            </p>
            <div className="employee-container">
              <Employee slug="awakened-hilde" mode="icon" enablePopover />
              <Employee slug="kaci-bins" mode="icon" enablePopover />
            </div>
            <p>
              Once these roles are fulfilled, the remaining slots are filled
              with damage dealers and buffers to maximize damage.
            </p>
            <StaticImage
              src="../../../images/counterside/generic/worm_3.webp"
              alt="Guide"
            />
            <p>Example run:</p>
            <Row className="video">
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="qNK6e2mCRdg" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="Boss2" title="Tyrant Gigas" className="with-bg">
            <h5>Tyrant Gigas</h5>
            <p>
              The general strategy for the boss fight does not change until
              stage 7.
            </p>
            <h5>Stage 1-6 Basic Strategy</h5>
            <p>
              The first 6 stages of Tyrant Gigas can be cleared without any
              special consideration. Of note, Tyrant Gigas’ phase change at ~55s
              remaining kills all melee units in range that do not have some
              form of damage cap (i.e. MDL, HP gates).
            </p>
            <StaticImage
              src="../../../images/counterside/generic/worm_4.webp"
              alt="Guide"
            />
            <h5>Stage 7+ Basic Strategy</h5>
            <p>
              Starting from stage 7, Tyrant Gigas’ ultimate skill becomes buffed
              with new properties. The ultimate skill damage is now changed to
              true damage, it cancels barriers, and the damage scaling increases
              the ultimate skill damage to absurd levels. In short, it cannot be
              directly tanked.{' '}
            </p>
            <p>
              To survive the ultimate, you will need to protect your team with
              non-cancellable shields. These come in the form of:
            </p>
            <ul>
              <li>Awakened Hilde (with alternium reactor)</li>
              <li>Villetta Nu/ Sigma*</li>
            </ul>
            <div className="employee-container">
              <Employee slug="awakened-hilde" mode="icon" enablePopover />
              <Operator unitId="32401" mode="icon" enablePopover />
              <Operator unitId="31701" mode="icon" enablePopover />
            </div>
            <p>
              *Both Villetta and Sigma provide shields, but Villetta is slightly
              better because her simpler operator proc order means teams aren’t
              forced to bring a defender to trigger the skill effect, losing a
              team slot in the process.
            </p>
            <p>
              As of writing, there is currently a bug with Gigas’ ultimate where
              it only checks the most recent shield layer for the
              non-cancellable property so using units like Awakened Shin Jia
              before it has performed all ultimate attacks can cause seemingly
              random deaths.{' '}
            </p>
            <h5>Team Building Considerations</h5>
            <p>
              These new mechanics are covered by characters that fulfill
              specific roles. These roles are:
            </p>
            <p>Example run:</p>
            <ul>
              <li>Battery</li>
              <li>Healer</li>
              <li>Shielder(s)</li>
            </ul>
            <p>
              <strong>Battery</strong>
            </p>
            <p>
              Similar to Britra raids, all backline units need to outrange the
              boss to avoid its attacks. As Lin Xien is a melee range unit, she
              tends to die. As such, the battery role is typically fulfilled by
              Sigma and Choi Ina.
            </p>
            <div className="employee-container">
              <Employee slug="choi-ina" mode="icon" enablePopover />
              <Operator unitId="31701" mode="icon" enablePopover />
            </div>
            <p>
              <strong>Healer</strong>
            </p>
            <p>
              The Healer role is fulfilled by a smaller subset of units; Rearm
              Chinatsu, and Carmen are the only healers that outrange the boss’
              attacks.
            </p>
            <div className="employee-container">
              <Employee slug="rearmed-chinatsu" mode="icon" enablePopover />
              <Employee slug="carmen" mode="icon" enablePopover />
            </div>
            <p>
              <strong>Shielder(s)</strong>
            </p>
            <p>
              As mentioned above, shielders with non-cancellable shields are
              needed to tank the boss’ ultimate skill. This role can only be
              fulfilled by Awakened Hilde, Villetta Nu (operator), and Sigma
              (operator).
            </p>
            <div className="employee-container">
              <Employee slug="awakened-hilde" mode="icon" enablePopover />
              <Operator unitId="32401" mode="icon" enablePopover />
              <Operator unitId="31701" mode="icon" enablePopover />
            </div>
            <p>
              Once these roles are fulfilled, the remaining slots are filled
              with damage dealers, buffers, and debuffers to maximize damage.
            </p>
            <StaticImage
              src="../../../images/counterside/generic/worm_5.webp"
              alt="Guide"
            />
            <p>Example run:</p>
            <Row className="video">
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="QzIIhpeuwlo" />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default CSGuildCoopPage;

export const Head: React.FC = () => (
  <Seo
    title="Guild Coop guide | Counter Side | Prydwen Institute"
    description="Guide for the Guild Coop mode in Counter Side."
  />
);
